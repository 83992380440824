import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "wrapper" }
const _hoisted_4 = { class: "nav" }
const _hoisted_5 = { class: "lang" }
const _hoisted_6 = ["onClick"]

import IntroComponent from "@/components/IntroComponent.vue";
import { onMounted, ref } from "vue";
import i18n_ from "@/i18n";

type Locales = typeof i18n.locale.value;

export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const i18n = i18n_.global;
let isLoaded = ref(false);

const setLanguage = (lang: Locales) => {
  localStorage.setItem("lang", lang);
  i18n.locale.value = lang;
};

onMounted(() => {
  document.fonts.ready.then(() => {
    isLoaded.value = true;
  });

  let language = (localStorage.getItem("lang") ||
    navigator.language) as Locales;
  if (i18n.availableLocales.includes(language)) {
    i18n.locale.value = language;
  }
});

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_router_view = _resolveComponent("router-view")!

  return (!_unref(isLoaded))
    ? (_openBlock(), _createElementBlock("div", _hoisted_1))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("nav", _hoisted_4, [
            _createVNode(_component_router_link, { to: "/" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("nav.cv")), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_router_link, { to: "/activity" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("nav.activity")), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_router_link, { to: "/project" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("nav.project")), 1)
              ]),
              _: 1
            })
          ]),
          _createElementVNode("nav", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$i18n.availableLocales, (lang) => {
              return (_openBlock(), _createElementBlock("div", {
                key: lang,
                class: _normalizeClass({ active: _ctx.$i18n.locale === lang }),
                onClick: ($event: any) => (setLanguage(lang as typeof $i18n.locale))
              }, _toDisplayString(lang.toUpperCase()), 11, _hoisted_6))
            }), 128))
          ]),
          _cache[0] || (_cache[0] = _createElementVNode("div", { class: "background" }, null, -1)),
          _cache[1] || (_cache[1] = _createElementVNode("div", { class: "background2" }, null, -1)),
          _createVNode(IntroComponent),
          _createVNode(_component_router_view)
        ])
      ]))
}
}

})